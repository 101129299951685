import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import cls from '../../../pages/Task/Task.module.scss';

interface Props {
    value?: string;
    onUpdate: (value: string) => void;
    placeholder?: string;
}

export const TextArea = ({value, onUpdate, placeholder}:Props) => {

    const {t} = useTranslation();
    const [name, setName] = useState<string>(value || '');

    useEffect(() => {
        setName(value || '');
    }, [value]);

    useEffect(() => {
        // Устанавливаем таймер
        const timer = setTimeout(() => {
            if (name !== value) {
                onUpdate(name);
            }
        }, 1000); // Задержка в 1000 мс

        // Очищаем таймер при размонтировании компонента или изменении inputValue
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]); // Эффект зависит от inputValue

    const onHandleChange = async (newName: string) => {
        setName(newName);
    };

    return (
        <Input.TextArea
            autoFocus={true}
            value={name}
            autoSize={{ minRows: 1, maxRows: 4 }}
            bordered={false}
            className={cls.name}
            placeholder={placeholder || t('Task name')!}
            onChange={(e) => onHandleChange(e.target.value)}
        />
    )
}
import { FC, useEffect } from 'react';
import {useInstance} from 'react-ioc';
import {useGetMeQuery} from 'generated/graphql';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {Store} from 'model/store/Store';

const GetMe: FC = () => {

    const { auth, domain } = useInstance(Store);

    const me = useGetMeQuery({
        fetchPolicy: 'no-cache',
        pollInterval: 60000,
        // onCompleted: (me) => {
        //     const role = me.me?.role?.name!
        //     const data = {
        //         username: me.me?.username!,
        //         color: me.me?.color!,
        //         focusedAt: me.me?.focusedAt
        //     }
        //     store.auth.setRoleAndData(role, data);
        //     store.domain.setDomains(me.me?.domains)
        // }
    });

    useEffect(() => {

        if (me.data?.me) {
            const role = me.data?.me?.role?.name!
            const data = {
                email: me.data.me.email!,
                username: me.data?.me?.username!,
                color: me.data?.me?.color!,
                focusedAt: me.data?.me?.focusedAt,
            }
            auth.setRoleAndData(role, data);
            domain.setDomains(me.data?.me?.domains)
            // если в доменах пусто, обнуляем выбранный
            if (me.data.me.domains.length === 0) {
                domain.selectDomain(null)
            }
            // если домены у меня есть, но ничего не выбрано
            if (domain.selectedDomain === null && me.data.me.domains.length > 0) {
                domain.selectDomain(me.data.me.domains[0]?.id!)
            }
            // если выбран домен, которого у меня больше нет
            if (_.filter(me.data.me.domains, item => item?.id === domain.selectedDomain).length === 0) {
                domain.selectDomain(me.data.me.domains[0]?.id!)
            }
        }
    }, [me, auth, domain])

    useEffect(() => {
        if (auth.refresh) {
            me.refetch().then();
            auth.setRefresh(false);
        }
    }, [auth, auth.refresh, me])

    return null
};

export default observer(GetMe);




import React from 'react';
import { useParams } from 'react-router-dom';
import { OutputData } from '@editorjs/editorjs';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { useTaskQuery } from '../../generated/graphql';

import { TaskEdit } from './TaskEdit';
import { TaskNew } from './TaskNew';


export interface IFormTask {
    name?: string,
    status: string,
    client?: string,
    epic?: string,
    users: string[],
    content: OutputData,
}


export const Task = observer(() => {

    const { id } = useParams();

    const query = useTaskQuery({
        variables: {
            id: id,
        },
        skip: id === 'new',
    });

    if (id === 'new') {
        return <TaskNew/>
    } else {
        if (query.data?.task?.data) {

            const data:IFormTask = {
                name: query.data.task.data.attributes?.name,
                status: query.data.task.data.attributes?.status!,
                client: query.data.task.data.attributes?.company?.data?.id!,
                epic: query.data.task.data.attributes?.epic?.data?.id!,
                users: _.map(query.data.task.data.attributes?.users?.data, user => user.id!),
                content: query.data.task.data.attributes?.content!,
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <TaskEdit {...data}/>
        } else {
            return <></>
        }
    }
});

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Form } from 'antd';

import Editor from '../../components/Editor/Editor';
import { SelectClient } from '../../components/Form/SelectClient/SelectClient';
import { SelectEpic } from '../../components/Form/SelectEpic/SelectEpic';
import { SelectStatus } from '../../components/Form/SelectStatus/SelectStatus';
import { SelectTeam } from '../../components/Form/SelectTeam/SelectTeam';
import { TextArea } from '../../components/Form/TextArea/TextArea';
import { IParam, Page } from '../../components/Page/Page';
import { Reports } from '../../components/Report/Reports';
import { Timers } from '../../components/Timer/TImers';
import { useUpdateTaskMutation } from '../../generated/graphql';

import { IFormTask } from './Task';

import cls from './Task.module.scss';

export const TaskEdit = (props: IFormTask) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [taskData, setTaskData] = useState<IFormTask>(props);
    const [updateTask, updateTaskData] = useUpdateTaskMutation();

    const goBack = () => {
        navigate(-1);
    };

    const onMutateTask = async (data:IFormTask) => {
        await updateTask({
            variables: {
                id: id!,
                data: {
                    name: data.name,
                    status: data.status,
                    company: data.client,
                    epic: data.epic,
                    users: data.users,
                    content: data.content,
                },
            },
        });
    };

    const onUpdateData = (data: IFormTask) => {
        if (data !== taskData) {
            onMutateTask(data).then()
            setTaskData(data);
        }
    }

    const onDeleteTask = async () => {
        await updateTask({
            variables: {
                id: id!,
                data: {
                    enabled: false,
                },
            },
            onCompleted: () => {
                goBack();
            },
        });
    };

    const menu = {
        items: [
            {
                key: 'delete',
                label: t('Delete'),
                danger: true,
                icon: <DeleteOutlined />,
                onClick: id !== 'new' ? onDeleteTask : goBack,
            },
        ],
    };

    const params: IParam[] = [
        {
            key: 'status',
            label: t('Status'),
            component: (
                <SelectStatus
                    value={taskData.status}
                    bordered={false}
                    variants={'borderless'}
                    className={cls.select}
                    onChange={(value: string) => onUpdateData({ ...taskData, status: value })}
                />
            ),
        },
        {
            key: 'client',
            label: t('Client'),
            component: (
                <SelectClient
                    value={taskData.client}
                    bordered={false}
                    variants={'borderless'}
                    className={cls.select}
                    onChange={(value: string) => onUpdateData({ ...taskData, client: value })}
                    onCreate={(value: string) => onUpdateData({ ...taskData, client: value })}
                />
            ),
        },
        {
            key: 'epic',
            label: t('Epic'),
            component: (
                <SelectEpic
                    value={taskData.epic}
                    bordered={false}
                    variants={'borderless'}
                    className={cls.select}
                    onChange={(value: string) => onUpdateData({ ...taskData, epic: value })}
                    onCreate={(value: string) => onUpdateData({ ...taskData, epic: value })}
                />
            ),
        },
        {
            key: 'team',
            label: t('Team'),
            component: (
                <SelectTeam
                    value={taskData.users}
                    bordered={false}
                    variants={'borderless'}
                    className={cls.select}
                    onChange={(value: string[]) => onUpdateData({ ...taskData, users: value })}
                />
            ),
        },
    ];

    return (
        <Page
            title={
                <TextArea
                    value={taskData.name}
                    onUpdate={(value: string) => onUpdateData({ ...taskData, name: value })}
                />
            }
            control={
                <Dropdown menu={menu} trigger={['click']}>
                    <Button icon={<MoreOutlined />} loading={updateTaskData.loading} />
                </Dropdown>
            }
        >
            <Page.Content>
                <Page.Params data={params} />
            </Page.Content>

            <Page.Divider first />

            <Page.Content>
                <Form.Item label={t('In focus')}>
                    <Timers taskId={id!} />
                </Form.Item>
                <Divider style={{ margin: '8px 0' }} />
            </Page.Content>

            <Page.Content>
                <Form.Item label={t('Reports')}>
                    <Reports taskId={id!} />
                </Form.Item>
                <Divider style={{ margin: '8px 0' }} />
            </Page.Content>

            <div className={cls.editor}>
                <Editor
                    data={taskData.content}
                    onUpdate={(value) => {
                        onUpdateData({ ...taskData, content: value });
                    }}
                />
            </div>
        </Page>
    );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';
import { goToTask } from 'routes';

import { SelectClient } from '../../components/Form/SelectClient/SelectClient';
import { SelectEpic } from '../../components/Form/SelectEpic/SelectEpic';
import { SelectStatus } from '../../components/Form/SelectStatus/SelectStatus';
import { SelectTeam } from '../../components/Form/SelectTeam/SelectTeam';
import { TextArea } from '../../components/Form/TextArea/TextArea';
import { IParam, Page } from '../../components/Page/Page';
import { useCreateTaskMutation } from '../../generated/graphql';
import { Store } from '../../model/store/Store';

import { IFormTask } from './Task';

import cls from './Task.module.scss';

export const TaskNew = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const store = useInstance(Store);

    const [taskData, setTaskData] = useState<IFormTask>({
        name: undefined,
        client: location.state?.client || undefined,
        epic: location.state?.epic || undefined,
        status: location.state?.status || 'New',
        content: {
            time: Date.now(),
            blocks: [],
        },
        users: [store.auth.id!],
    });

    const [createTask] = useCreateTaskMutation();

    const goBack = () => {
        navigate(-1);
    };

    const onMutateTask = async (data:IFormTask) => {
        if (data.name) {
            await createTask({
                variables: {
                    data: {
                        name: data.name,
                        content: data.content,
                        status: data.status,
                        company: data.client,
                        epic: data.epic,
                        users: data.users,
                        domain: store.domain.selectedDomain,
                    },
                },
                onCompleted: (res) => {
                    navigate(goToTask(res.createTask?.data?.id!), { replace: true });
                },
            });
        }
    };

    const onUpdateData = (data: IFormTask) => {
        if (data !== taskData) {
            onMutateTask(data).then()
            setTaskData(data);
        }
    }

    const menu = {
        items: [
            {
                key: 'delete',
                label: t('Delete'),
                danger: true,
                icon: <DeleteOutlined />,
                onClick: goBack,
            },
        ],
    };

    const params: IParam[] = [
        {
            key: 'status',
            label: t('Status'),
            component: (
                <SelectStatus
                    value={taskData.status}
                    bordered={false}
                    variants={'borderless'}
                    className={cls.select}
                    onChange={(value: string) => onUpdateData({ ...taskData, status: value })}
                />
            ),
        },
        {
            key: 'client',
            label: t('Client'),
            component: (
                <SelectClient
                    value={taskData.client}
                    bordered={false}
                    variants={'borderless'}
                    className={cls.select}
                    onChange={(value: string) => onUpdateData({ ...taskData, client: value })}
                    onCreate={(value: string) => onUpdateData({ ...taskData, client: value })}
                />
            ),
        },
        {
            key: 'epic',
            label: t('Epic'),
            component: (
                <SelectEpic
                    value={taskData.epic}
                    bordered={false}
                    variants={'borderless'}
                    className={cls.select}
                    onChange={(value: string) => onUpdateData({ ...taskData, epic: value })}
                    onCreate={(value: string) => onUpdateData({ ...taskData, epic: value })}
                />
            ),
        },
        {
            key: 'team',
            label: t('Team'),
            component: (
                <SelectTeam
                    value={taskData.users}
                    bordered={false}
                    variants={'borderless'}
                    className={cls.select}
                    onChange={(value: string[]) => onUpdateData({ ...taskData, users: value })}
                />
            ),
        },
    ];

    return (
        <Page
            title={
                <TextArea
                    value={taskData.name}
                    onUpdate={(value: string) => onUpdateData({ ...taskData, name: value })}
                    placeholder={t('New task')!}
                />
            }
            control={
                <Dropdown menu={menu} trigger={['click']}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>
            }
        >
            <Page.Content>
                <Page.Params data={params} />
            </Page.Content>

            <Page.Divider first />

            {/*<div className={cls.editor}>*/}
            {/*    <Editor*/}
            {/*        data={taskData.content}*/}
            {/*        onUpdate={(value) => {*/}
            {/*            onUpdateData({...taskData, content: value});*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
        </Page>
    );
});
